@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
    .rtl {
        direction: rtl;
    }
}

[x-cloak=""] {
    display: none;
}

.spinnerholder {
    width: 100%;
    top: 0;
    z-index: 999;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 80%);
}

.round7 {
    width: 4rem;
    height: 4rem;
    box-sizing: border-box;
    position: relative;
    border-radius: 50%;
    border-top: 6px solid #00acc1;
    border-left: 6px solid transparent;
    border-bottom: 6px solid #00acc1;
    border-right: 6px solid transparent;
    animation: 1.5s infinite rotateMe;
    animation-delay: 0.2s;
}

.round7:after {
    content: "";
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    left: 0.4rem;
    top: 0.4rem;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 5px solid #60dbeb;
    border-left: 5px solid transparent;
    border-bottom: 5px solid #60dbeb;
    border-right: 5px solid transparent;
    animation: 1.5s infinite rotateMe;
}

@keyframes rotateMe {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.atsidebar {
    top: 3.7rem;
}

.attable {
    margin-top: 50px;
}

.atbtn {
    background-color: rgb(37 99 235);
}
/* n
.dark .atbtn {
    background-color: rgb(30 58 138);
}

.dark .jodit-toolbar__box,
.dark .jodit-wysiwyg,
.dark .jodit-status-bar,
.dark .rti--container,
.dark .rti--input {
    background-color: rgb(55 65 81) !important;
    color: #ffffff;
}

.dark .jodit-toolbar-button__button svg,
.dark .jodit-toolbar-button__trigger svg {
    fill: #ffffff;
}

.dark .jodit-toolbar-button__button:hover,
.dark .jodit-toolbar-button__trigger:hover,
.dark .jodit-xpath a:hover,
.dark .rti--tag, */
/* .dark body {
    background-color: rgb(17 24 39) !important;
} */

/* .jodit-status-bar-link {
    display: none;
} */

/* .dark .jodit-xpath a {
    color: #ffffff;
} */


@media only screen and (max-width: 640px) {
    .atsidebar {
        top: 0;
    }
}

.uploadImageIcon::after {
    content: 'kazim';
    height: 20px;
    width: 20px;
    display: flex;
}