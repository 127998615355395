/* 
.card {
    display: flex;
    align-items: center;
    gap: 28px;
    color: #171721;
    padding: 32px;
    background: linear-gradient(135deg, #fff, #f0f0f0 40%);
    position: relative;
    z-index: 1;
    border-radius: 1rem;
    justify-content: space-between;
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.15);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.15);
  }
  
  .card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background: linear-gradient(135deg, #fff, #f0f0f0 40%);
    z-index: -2;
  }
  
  .card::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 1rem;
    background: linear-gradient(90deg, #fff, #eaeaea);
    transition: box-shadow 0.3s ease;
    z-index: -1;
  }
  
  .card .info {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .card .info .sub {
    color: #0ea3d4;
    line-height: 28px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .card .info .title {
    max-width: 260px;
    line-height: 28px;
    font-size: 17px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .card .info .btn {
    margin-top: 28px;
    color: #171721;
    background: transparent;
    border: unset;
    border-radius: 16px;
    overflow: hidden;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 16px;
    margin-right: auto;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: background 0.3s ease;
  }
  
  .card .info .btn::before,
  .card .info .btn::after {
    content: '';
    position: absolute;
  }
  
  .card .info .btn::before {
    left: 50%;
    top: 50%;
    background: linear-gradient(90deg, #0ea3d4 0%, transparent 45%, transparent 55%, #0ea3d4 100%);
    transform: translate(-50%, -50%) rotate(55deg);
    width: 100%;
    height: 240%;
    border-radius: 16px;
    z-index: -2;
    opacity: 0.4;
    transition: all 0.3s ease;
  }
  
  .card .info .btn::after {
    left: 2px;
    top: 2px;
    background: #fff;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 16px;
    z-index: -1;
  }
  
  .card .info .btn:hover::before {
    animation: 5s move infinite linear;
    opacity: 1;
  }
  
  .card .image {
    width: 115px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 8px 12px 16px rgba(76, 175, 237, 0.5);
    position: relative;
    z-index: 0;
  }
  
  .card .image::before {
    content: '';
    position: absolute;
    background: linear-gradient(110deg, #0ea3d4 10%, #2783b5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -2;
  }
  
  .card .image::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 50%;
    background: linear-gradient(90deg, #0e99b5, #4c8de1);
    box-shadow: 6px 6px 14px -6px rgba(14, 163, 212, 0.5) inset;
    z-index: -1;
  }
  
  .card .image > i {
    font-size: 30px;
    color: #0ea3d4;
  }
  
  @keyframes move {
    0% {
      transform: translate(-50%, -50%) rotate(55deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(415deg);
    }
  }
  

.dark .card {
    display: flex;
    align-items: center;
    gap: 28px;
    color: #fcfcfc;
    padding: 32px;
    border-radius: 1rem;
    background: linear-gradient(135deg, #0ea3d4, #0e0c33 40%);
    position: relative;
    z-index: 1;
    justify-content: space-between;
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.15);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.15);
  }
  
  .dark .card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background: linear-gradient(135deg, #0ea3d4, #0e0c33 40%);
    z-index: -2;
  }
  
  .dark .card::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 1rem;
    background: linear-gradient(90deg, #171721, #0c5681);
    transition: box-shadow 0.3s ease;
    z-index: -1;
  }
  
  .dark .card .info {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .dark .card .info .sub {
    color: #0ea3d4;
    line-height: 28px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .dark  .card .info .title {
    max-width: 260px;
    line-height: 28px;
    font-size: 17px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  
  .dark .card .info .btn {
    margin-top: 28px;
    color: #fff;
    background: transparent;
    border: unset;
    border-radius: 16px;
    overflow: hidden;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 16px;
    margin-right: auto;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: background 0.3s ease;
  }
  
  .dark .card .info .btn::before,
  .dark .card .info .btn::after {
    content: '';
    position: absolute;
  }
  
  .dark .card .info .btn::before {
    left: 50%;
    top: 50%;
    background: linear-gradient(90deg, #0ea3d4 0%, transparent 45%, transparent 55%, #0ea3d4 100%);
    transform: translate(-50%, -50%) rotate(55deg);
    width: 100%;
    height: 240%;
    border-radius: 16px;
    z-index: -2;
    opacity: 0.4;
    transition: all 0.3s ease;
  }
  
  .dark .card .info .btn::after {
    left: 2px;
    top: 2px;
    background: #171721;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 16px;
    z-index: -1;
  }
  
  .dark .card .info .btn:hover::before {
    animation: 5s move infinite linear;
    opacity: 1;
  }
  
  .dark .card .image {
    width: 115px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 8px 12px 16px #4cafed;
    position: relative;
    z-index: 0;
  }
  .dark .card .image::before {
    content: '';
    position: absolute;
    background: linear-gradient(110deg, #0ea3d4 10%, #2783b5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -2;
  }
  
  .dark .card .image::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 50%;
    background: linear-gradient(90deg, #0e99b5, #4c8de1);
    box-shadow: 6px 6px 14px -6px #0ea3d450 inset;
    z-index: -1;
  }
  
  .dark .card .image > i {
    font-size: 30px;
    color: #0ea3d4;
  }
  
  @keyframes move {
    0% {transform: translate(-50%, -50%)  rotate(55deg);}
    100% {transform: translate(-50%, -50%)  rotate(415deg);}
  }
  .aqpagination {
    background: linear-gradient(90deg, #fff, #eaeaea);
  }

  .dark .aqpagination{
    background: linear-gradient(90deg, #171721, #0c5681);
  } */
/* .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin-block: 2rem;
    gap: 2rem;
  }
   */


.card {
  height: 410px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #F2F2F2;
  background: linear-gradient(to right, #FFFFFF, #F2F2F2);
}


.card__header img {
  max-width: 100%;
  display: block;
  object-fit: cover;
  height: 220px;
}

.card__body {
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.card__body p p {
  font-size: 14px;
}

.card__body h4 {
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 800;
}

.card__footer {
  display: flex;
  padding: 0 1rem;
  margin-top: auto;
  margin-bottom: 10px;
}

.user {
  display: flex;
  gap: .5rem;
}

.user__image {
  border-radius: 50%;
  width: 15%;
}

.user__info>small {
  color: #666;
}